<template>
  <div :class="{ hidden: hidden }" class="pagination-container">
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      :disabled="disabled"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
// import { scrollTo } from '@/utils/scrollTo';
const scrollTo = (x, y) => x;

export default {
  name: 'Pagination',
  props: {
    autoScroll: {
      default: true,
      type: Boolean
    },
    background: {
      default: true,
      type: Boolean
    },
    layout: {
      default: 'prev, pager, next, jumper, ->, total, sizes',
      type: String
    },
    hidden: {
      default: false,
      type: Boolean
    },
    limit: {
      default: 20,
      type: Number
    },
    page: {
      default: 1,
      type: Number
    },
    pageSizes: {
      default() {
        return [10, 20, 30, 50];
      },
      type: Array
    },
    total: {
      required: true,
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit('update:page', val);
      }
    },
    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit('update:limit', val);
      }
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit('pagination', { limit: this.pageSize, page: val });
      if (this.autoScroll) {
        scrollTo(0, 800); // @fixme: What is this kind of magic? ~ Twan
      }
    },
    handleSizeChange(val) {
      this.$emit('pagination', { limit: val, page: this.currentPage });
      if (this.autoScroll) {
        scrollTo(0, 800); // @fixme: What is this kind of magic? ~ Twan
      }
    }
  }
};
</script>

<style scoped>
.pagination-container {
  margin-bottom: 0.3em;
}

.pagination-container.hidden {
  display: none;
}
</style>
